// src/components/SettingsModal.js
import React from 'react';
import { Modal, Tab, Nav, Row, Col, Button } from 'react-bootstrap';

// Import individual components for each tab
import UpdateAccounts from './settings/UpdateAccounts';
import ProfileSettings from './settings/ProfileSettings';
import PaymentInfo from './settings/PaymentInfo';

// Import the CSS file (if not already imported globally)
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/SettingsModal.css';

function SettingsModal({ show, handleClose, bankAccounts }) {
  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      {/* Modal Header */}
      <Modal.Header closeButton>
        <Modal.Title>Account Settings</Modal.Title>
      </Modal.Header>

      {/* Modal Body */}
      <Modal.Body>
        <Tab.Container defaultActiveKey="update-accounts">
          <Row>
            <Col md={3}>
              <Nav variant="pills" >
                <Nav.Item>
                  <Nav.Link eventKey="update-accounts" >Bank Accounts</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="profile-settings">Profile Settings</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="payment-info">Payment Info</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="privacy-policy">Privacy Policy</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md={9}>
              <Tab.Content>
                <Tab.Pane eventKey="update-accounts">
                  <UpdateAccounts bankAccounts={bankAccounts} />
                </Tab.Pane>
                <Tab.Pane eventKey="profile-settings">
                  <ProfileSettings />
                </Tab.Pane>
                <Tab.Pane eventKey="payment-info">
                  <PaymentInfo />
                </Tab.Pane>
                <Tab.Pane eventKey="privacy-policy">
                  <div>
                    <h5>Privacy Policy</h5>
                    <iframe
                      src="/privacypolicy.pdf"
                      title="Privacy Policy"
                      style={{ width: '100%', height: '500px', border: 'none' }}
                    ></iframe>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>

      {/* Modal Footer */}
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SettingsModal;
