import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '../pages/LandingPage.css'; // Import your Webflow CSS here

function RegisterPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [hasAcceptedPolicy, setHasAcceptedPolicy] = useState(false);

  const { register } = useAuth();
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    const storedFormData = JSON.parse(localStorage.getItem('registerFormData'));
    if (storedFormData) {
      setFormData((prevData) => ({
        ...prevData,
        name: storedFormData.name || '',
        email: storedFormData.email || ''
      }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updated = { ...formData, [name]: value };
    setFormData(updated);

    // Save name and email in localStorage (not password fields)
    if (name === 'name' || name === 'email') {
      localStorage.setItem(
        'registerFormData',
        JSON.stringify({
          name: updated.name,
          email: updated.email
        })
      );
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');

    // Must accept policy
    if (!hasAcceptedPolicy) {
      setError('You must accept the privacy policy before registering.');
      return;
    }

    // Passwords must match
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const userData = {
        name: formData.name,
        email: formData.email,
        password: formData.password
      };
      await register(userData);
      // If registration is successful, redirect
      navigate('/verify-email', { state: { email: formData.email } });
    } catch (err) {
      console.error(err);
      setError(err?.response?.data?.message || 'Registration failed');
    }
  };

  const handleAcceptPolicyClick = (e) => {
    // If user hasn’t accepted yet, show the policy instead of toggling the checkbox
    if (!hasAcceptedPolicy) {
      e.preventDefault();
      showPrivacyPolicy();
    } else {
      // If user has accepted, allow them to uncheck
      setHasAcceptedPolicy(false);
    }
  };

  const showPrivacyPolicy = () => {
    MySwal.fire({
      title: 'Privacy Policy',
      html: `
        <div style="height: 400px; overflow-y: hidden;">
          <iframe
            src="/privacypolicy.pdf"
            style="width: 100%; height: 100%; border: none;"
          ></iframe>
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: 'Accept',
      confirmButtonColor: '#3085d6',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#d33',
      showConfirmButton: true,
      didOpen: (popup) => {
        const acceptButton = MySwal.getConfirmButton();
        acceptButton.disabled = true;

        const iframe = popup.querySelector('iframe');
        if (iframe) {
          // Poll until user scrolls to the bottom of the PDF
          iframe.addEventListener('load', () => {
            const checkScroll = setInterval(() => {
              try {
                const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                const { scrollingElement } = iframeDoc;
                if (
                  scrollingElement.scrollTop + scrollingElement.clientHeight >=
                  scrollingElement.scrollHeight - 5
                ) {
                  acceptButton.disabled = false;
                  clearInterval(checkScroll);
                }
              } catch (error) {
                console.error('Cross-origin iframe access issue:', error);
              }
            }, 500);
          });
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        setHasAcceptedPolicy(true);
      }
    });
  };

  return (
    <>
      <section className="section login">
        <div className="gradient-image" />
        <div className="container-large">
          <div className="card-item login">
            <h1 className="heading medium">Sign up</h1>
            <div className="spacer-40" />

            <div className="form-block w-form">
              <form onSubmit={handleRegister} className="login-form">
                {/* Name */}
                <div className="text-box">
                  <label htmlFor="name" className="field-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="text-input w-input"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* Email */}
                <div className="text-box">
                  <label htmlFor="email" className="field-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="text-input w-input"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* Password */}
                <div className="text-box">
                  <label htmlFor="password" className="field-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="text-input w-input"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* Confirm Password */}
                <div className="text-box">
                  <label htmlFor="confirmPassword" className="field-label">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className="text-input w-input"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* Privacy Policy Checkbox */}
                <div className="text-box" style={{ marginTop: '16px' }}>
                  <input
                    type="checkbox"
                    id="acceptPolicy"
                    checked={hasAcceptedPolicy}
                    onClick={handleAcceptPolicyClick}
                    style={{ marginRight: '8px' }}
                  />
                  <label htmlFor="acceptPolicy" className="field-label">
                    I accept the Privacy Policy
                  </label>
                </div>

                <div className="spacer-16" />

                {/* Submit button */}
                <input
                  type="submit"
                  data-wait="Please wait..."
                  className="button full-width w-button"
                  value="Register"
                />
              </form>

              {/* Success/Failure Messages (inline style for visibility) */}
              {success && (
                <div
                  className="success-message w-form-done"
                  style={{ display: 'block' }}
                >
                  <div>{success}</div>
                </div>
              )}
              {error && (
                <div
                  className="error-message w-form-fail"
                  style={{ display: 'block' }}
                >
                  <div>{error}</div>
                </div>
              )}

              <div className="spacer-16" />
              {/* Link to Login */}
              <div className="text-box" style={{ textAlign: 'center' , color: 'white'}}>
                Already have an account?{' '}
                <Link to="/login" className="text-link" style={{ color: '#ff0' }}>
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RegisterPage;
