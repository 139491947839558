// src/pages/LandingPage.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';  
import { useAuth } from '../contexts/AuthContext';
import MobileAlert from './components/MobileAlert';
import HeroImage from './components/HeroImage';
import PoweredByPlaid from './components/PoweredBy';
import DynamicText from './components/DynamicText';
import SteppedGuide from './components/SteppedGuide';

function LandingPage() {
  const navigate = useNavigate();
  const { auth } = useAuth();

  // Example rotating text in hero
  const [exampleIndex, setExampleIndex] = useState(0);

  // For detecting mobile
  const [showMobileAlert, setShowMobileAlert] = useState(false);

  // Hero example rule ideas
  const examples = [
    '“Send a text if my restaurant spending exceeds $500.”',
    '“Email me whenever my balance drops below $100.”',
    '“Notify me by SMS if a deposit of $1,000 or more is received.”',
    '“Alert me when monthly expenses surpass $2,000.”',
    '“Warn me of any transactions from foreign countries.”',
  ];

  // Rotate the hero example text every 6 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setExampleIndex((prev) => (prev + 1) % examples.length);
    }, 6000);
    return () => clearInterval(intervalId);
  }, [examples.length]);

  // If user is logged in, go to /home
  useEffect(() => {
    if (auth.user) {
      navigate('/home');
    }
  }, [auth, navigate]);
  
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  // Check mobile device
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobileDevice = /Mobi|Android|iPhone|iPad|iPod|webOS|BlackBerry|Windows Phone/i.test(userAgent);
    if (isMobileDevice) {
      setShowMobileAlert(true);
    }
  }, []);

  // CTA button handlers
  const handleLoginClick = () => {
    navigate('/login');
  };
  const handleRegisterClick = () => {
    navigate('/register');
  };
  const handleGuestClick = () => {
    navigate('/home');
  };

  // **Rotating Testimonials** state
  const testimonials = [
    { quote: '“Nomi helped me automate my budget ...”', author: '— Sarah W.' },
    { quote: '“Game-changer! I connected two checking accounts ...”', author: '— James K.' },
    { quote: '“Perfect tool to keep me updated ...”', author: '— Emily R.' },
    { quote: '“Finally, a simple way to watch out for hidden fees ...”', author: '— DeAndre P.' },
    { quote: '“I love setting up my own rules. ...”', author: '— Ava L.' },
    { quote: '“Totally simplified my finances ...”', author: '— Daniel M.' },
    { quote: '“I never miss a deposit anymore ...”', author: '— Grace Y.' },
    { quote: '“Superb security features. ...”', author: '— Mateo H.' },
  ];
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  // Rotate testimonials every 5 seconds
  useEffect(() => {
    const tInterval = setInterval(() => {
      setCurrentTestimonialIndex((prev) => (prev + 1) % testimonials.length);
    }, 5000);
    return () => clearInterval(tInterval);
  }, [testimonials.length]);

  return (
<>
  <meta charSet="utf-8" />
  <title>Nomi · Webflow HTML website template</title>
  <meta
    content="Nomi is a high-quality template for SaaS tech startups. It has multiple pages, sections and components to suit your needs. Take your company's online presence to the next level."
    name="description"
  />
  <meta content="Nomi · Webflow HTML website template" property="og:title" />
  <meta
    content="Nomi is a high-quality template for SaaS tech startups. It has multiple pages, sections and components to suit your needs. Take your company's online presence to the next level."
    property="og:description"
  />
  <meta content="/images/Nomi-opengraph-image.png" property="og:image" />
  <meta
    content="Nomi · Webflow HTML website template"
    property="twitter:title"
  />
  <meta
    content="Nomi is a high-quality template for SaaS tech startups. It has multiple pages, sections and components to suit your needs. Take your company's online presence to the next level."
    property="twitter:description"
  />
  <meta content="/images/Nomi-opengraph-image.png" property="twitter:image" />
  <meta property="og:type" content="website" />
  <meta content="summary_large_image" name="twitter:card" />
  <meta content="width=device-width, initial-scale=1" name="viewport" />
  <meta content="Webflow" name="generator" />
  <link href="css/webflow-style.css" rel="stylesheet" type="text/css" />
  <link href="/images/favicon.png" rel="shortcut icon" type="image/x-icon" />
  <link href="/images/app-icon.png" rel="apple-touch-icon" />
  <a
    href="https://www.bryntaylor.co.uk/platform/webflow?ref=template"
    target="_blank"
    className="section banner w-inline-block"
  >
    <div style = {{color: '#ffc44d'}}  className="container-large">
      <div>Peekaboo!</div>
    </div>
  </a>
  <div
    data-animation="default"
    className="nav w-nav"
    data-easing2="ease"
    fs-scrolldisable-element="smart-nav"
    data-easing="ease"
    data-collapse="medium"
    data-w-id="abad23f6-3206-d95e-518e-2548f4c46996"
    role="banner"
    data-duration={400}
  >
    <div className="nav-container">
      <div className="logo-wrapper">
        <a href="/" aria-current="page" className="logo w-nav-brand w--current">
          <img
            src="/Nomi1500.png"
            loading="lazy"
            width="Auto"
            height={65}
            alt="Logo"
          />
        </a>
        <nav
          role="navigation"
          id="w-node-abad23f6-3206-d95e-518e-2548f4c4699b-f4c46996"
          className="nav-menu w-nav-menu"
        >
          <a onClick={() => scrollToSection('features')} style = {{color: '#ffc44d'}} className="nav-link w-nav-link">
            Features
          </a>
          <a onClick={() => scrollToSection('about')} style = {{color: '#ffc44d'}}  className="nav-link w-nav-link">
            About
          </a>

        </nav>
      </div>
      <div
        id="w-node-abad23f6-3206-d95e-518e-2548f4c469b0-f4c46996"
        className="actions-wrapper"
      >
        <a
          onClick = {handleLoginClick}
          className="button-secondary small hide-mobile-portrait w-button"
        >
          Sign in
        </a>
        <a onClick = {handleRegisterClick} className="button small hide-mobile-portrait w-button">
          Get started
        </a>
        <a 
          onClick={handleGuestClick}
          className="button-secondary small hide-mobile-portrait w-button"
          style={{
            border: '1px solid #14b67a',
            marginLeft: '8px'
          }}
        >
          Continue as guest
        </a>
        <div className="nav-menu-button w-nav-button">
          <div className="menu-icon">
            <div className="menu-icon-line-top" />
            <div className="menu-icon-line-bottom" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <section className="section">
    <div className="container-large">
      <div className="header-layout">
        <div className="header-content">
          <h1
            data-w-id="05c97b58-b1ac-eabe-afbc-3861dce3b03f"
            style={{
              WebkitTransform:
                "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              MozTransform:
                "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              msTransform:
                "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              transform:
                "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              opacity: 1
            }}
            className="heading extra-large"
          >
            <span className="text-gradient-overlay">Nomi Finance</span>
          </h1>
          <div className="spacer-32" />
          <p
            data-w-id="05c97b58-b1ac-eabe-afbc-3861dce3b041"
            style={{
              WebkitTransform:
                "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              MozTransform:
                "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              msTransform:
                "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              transform:
                "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              opacity: 1,
              color: '#ffda8f'
            }}
            className="paragraph large"
          >
            Powerful automations for your money.
          </p>
          <div className="spacer-32" />
          <div className="button-group">
            <a
              data-w-id="05c97b58-b1ac-eabe-afbc-3861dce3b045"
              style={{
                WebkitTransform:
                  "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1,
              }}
              onClick = {handleRegisterClick}
              className="button w-button"
            >
              Get started
            </a>
            <a
              href="#learn-more"
              data-w-id="05c97b58-b1ac-eabe-afbc-3861dce3b047"
              style={{
                WebkitTransform:
                  "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: 1
              }}
              className="button-secondary w-button"
            >
              Learn more
            </a>
          </div>
          <div className="spacer-64" />
          <div
          data-w-id="2469359d-caa4-0f3d-e0f9-ac9d68bc0583"
          style={{
            // WebkitTransform:
            //   "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            // MozTransform:
            //   "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            // msTransform:
            //   "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            // transform:
            //   "translate3d(0, 24px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
            opacity: 1
          }}
          className="social-proof-logos-wrapper"
        >
          <PoweredByPlaid />
        </div>
        </div>
        <div className="image-wrapper">
        <div className="image-wrapper">
          <HeroImage />
        </div>
        </div>
      </div>
    </div>
  </section>
  <section className="section" style={{ paddingTop: '0' }}>
  <div className="container-large">
    <div style={{
      borderLeft: '4px solid #14b67a',
      padding: '2rem 0 2rem 2rem',
      margin: '2rem 0',
    }}>
      <h2 
        className="heading large" 
        style={{
          color: '#ffc44d',
          maxWidth: '900px',
          lineHeight: '1.2',
          fontWeight: '400'
        }}
      >
        "No more subscriptions to complicated dashboards. Nomi is the one-stop-shop for all your financial automation needs."
      </h2>
      <div className="spacer-16" />
      <p 
        className="paragraph large"
        style={{
          color: '#ffda8f',
          fontSize: '1.2rem',
          opacity: '1',

        }}
      >
        Simplify your money, one rule at a time. 
      </p>
    </div>
  </div>
</section>
  <section id="features" className="section">
    <div id="learn-more" className="container-large">
      <h2 className="heading large" style={{color: "#ffc44d"}}>
        Simple automations. Flexible rules.
      </h2>
      <div className="spacer-80" />
      <div className="w-layout-grid card-list">
        <div
          id="w-node-_38f718e7-ef7d-cf1b-5d32-9110617504c1-617504ba"
          className="card-item"
        >
          <img
            src="/images/layout-dashboard.svg"
            loading="lazy"
            alt="Dashboard icon"
            className="icon-large"
          />
          <div className="spacer-128" />
          <h3 className="heading medium">Automate your finances with customizable rules</h3>
          <div className="spacer-16" />
          <p className="paragraph">
            Set up rules that alert you when important things happen to your
            money. Stay notified, safe, and relaxed knowing exactly what's going
            on with your cashflows.
          </p>
        </div>
        <div className="card-item">
          <img
            src="/images/radar.svg"
            loading="lazy"
            alt="Radar icon"
            className="icon-large"
          />
          <div className="spacer-32" />
          <h3 className="heading medium">Real-time updates</h3>
          <div className="spacer-16" />
          <p className="paragraph">
            Create rules with real-time data. Stay on top
            of changes as they happen, keep yourself informed.
          </p>
        </div>
        <div
          id="w-node-d05e47e2-aa32-2547-947a-66fd2ccb7be0-617504ba"
          className="card-item"
        >
          <img
            src="/images/wand-sparkles.svg"
            loading="lazy"
            alt="Magic icon"
            className="icon-large"
          />
          <div className="spacer-128" />
          <h3 className="heading medium">Easy to use</h3>
          <div className="spacer-16" />
          <p className="paragraph">
            Visualize simple and complex rules in the same place. No coding or technical skills required.
          </p>
        </div>
        <div className="card-item">
          <img
            src="/images/mouse-pointer-click.svg"
            loading="lazy"
            alt="Mouse icon"
            className="icon-large"
          />
          <div className="spacer-32" />
          <h3 className="heading medium">Own your information</h3>
          <div className="spacer-16" />
          <p className="paragraph">
            We prioritize your privacy over everything else. Plaid helps us do this. Learn more{' '}
            <a 
              href="https://plaid.com/safety/" 
              target="_blank" 
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' , color: '#ffda8f'}}
            >
              here
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  </section>
  <section id="about" className="section">
    <div className="container-large">
      <div className="features-list">
        <div
          id="w-node-ae1eadcb-aaf8-55e9-7e1a-c032c515ada0-4381e100"
          className="w-layout-grid feature-item"
        >
          <div className="feature-content">
          <DynamicText 
            text="Automated rules at your fingertips"
            baseClassName="text-color-accent text-weight-medium"
          />
            <div className="spacer-16" />
            <h2 className="heading large">
              <span className="text-gradient-overlay">
                Real-time insights for faster decisions
              </span>
            </h2>
          </div>
          <div className="image-wrapper">
            <img
              src="/images/preset_rules.png"
              loading="lazy"
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 83vw, (max-width: 991px) 87vw, 84vw"
              srcSet="/images/preset_rules.png 500w, /images/preset_rules.png 800w, /images/preset_rules.png 2400w"
              alt="Product image"
              className="feature-image"
            />
          </div>

        </div>
        <div
          id="w-node-c5cef4ae-8c0e-4b3b-8099-aaf990fe1fce-4381e100"
          className="w-layout-grid feature-item"
        >
          <div className="feature-content">
            <div className="text-color-accent text-weight-medium">
              Quick and simple. Set up automations in minutes.
            </div>
            <div className="spacer-16" />
            <h2 className="heading large">
              <span className="text-gradient-overlay">
                Its as easy as 1,2,3...
              </span>
            </h2>
          </div>
          <div className="image-wrapper">
            <img
              src="123.svg"
              style={{
                border: '2px solid #14b67a',
                borderRadius: '12px',
              }}
              loading="lazy"
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 83vw, (max-width: 991px) 87vw, 84vw"
              srcSet="/123.svg 500w, /123.svg 800w, /images/torch-product-image-3.avif 1080w, /123.svg 2400w"
              alt="Product image"
              className="feature-image"
            />
          </div>

        </div>
      </div>
    </div>
  </section>
  <section id="contact" className="section">
    <div className="container-large">
      <h2 className="heading large">
        <span className="text-gradient-overlay">Kind words</span>
      </h2>
      <div className="spacer-64" />
      <div className="w-layout-grid testimonials-list">
        <div
          id="w-node-fb4259e2-12f1-5169-34ca-8b2c403fd7d0-b7acb5bf"
          className="testimonials-card"
        >
          <div className="testimonials-content-top">

              
          <h2>
            <span className="text-gradient-overlay">From families</span>
          </h2>

            <div className="spacer-32" />
            <div className="paragraph large">
              I'm teaching my 3 kids to budget, now I'm not pulling my hair out.
            </div>
            <div className="spacer-32" />
          </div>
          <div className="testimonial-details">
            <div className="testimonial-image-wrapper">
            </div>
            <div className="testimonial-details-text">
              <div className="paragraph text-weight-medium">-Emily</div>
              <div className="text-color-muted">
                <div className="paragraph small">Vancouver, BC</div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="w-node-_8ca0cb1f-d888-9151-9631-c252d59b8206-b7acb5bf"
          className="testimonials-card"
        >
          <div className="testimonials-content-top">
            <h2>
              <span className="text-gradient-overlay">From business owners</span>
            </h2>
            <div className="spacer-32" />
            <div className="paragraph large">
              Our team uses Nomi to balance corporate accounts all in one place. Saves us a ton of time.
            </div>
            <div className="spacer-32" />
          </div>
          <div className="testimonial-details">
            <div className="testimonial-image-wrapper">
            </div>
            <div className="testimonial-details-text">
              <div className="paragraph text-weight-medium">-Xavier</div>
              <div className="text-color-muted">
                <div className="paragraph small">Charlottesville, VA</div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="w-node-d9785a82-2a84-de91-5915-14c5bba0189f-b7acb5bf"
          className="testimonials-card"
        >
          <div className="testimonials-content-top">
          <h2>
            <span className="text-gradient-overlay">From professionals</span>
          </h2>
            <div className="spacer-32" />
            <div className="paragraph large">
              I'm more on top of things with Nomi. I choose when and how I get notified, it's very clean.
            </div>
            <div className="spacer-32" />
          </div>
          <div className="testimonial-details">
            <div className="testimonial-image-wrapper">
            </div>
            <div className="testimonial-details-text">
              <div className="paragraph text-weight-medium">
                -Rejashree
              </div>
              <div className="text-color-muted">
                <div className="paragraph small">New York City, NY</div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </section>
  <section className="section">
    <div className="container-small">
      <div className="w-layout-grid faq-wrapper">
        <div className="faq-content">
          <h2>
            <span className="text-gradient-overlay">FAQs</span>
          </h2>
          <div className="spacer-24" />
          <p className="paragraph large">
            Answers to questions you might have about Nomi.
          </p>
        </div>
        <div className="w-layout-grid faq-list">
          <div className="faq-accordion">
            <div
              data-w-id="ec766c24-12b7-b94d-ff49-9f5d83ef1eb9"
              className="faq-question"
            >
              <h3 className="heading regular">What does Nomi do?</h3>
              <img
                src="/images/plus.svg"
                loading="lazy"
                alt="Plus icon"
                className="faq-icon"
              />
            </div>
            <div className="faq-answer" style={{color: 'black'}}>
              <p>
                Nomi is a financial platform that let's you create rules for you money. Rules are flexible IF statement automations that trigger alerts or transactions
                when specific events occur.
              </p>
              <p>
                We built Nomi becuase we were tired of manually repeating chores with our money. We wanted flexible automations we could control, no matter how niche they were!
              </p>
              <div className="spacer-40" />
            </div>
          </div>
          <div className="faq-accordion">
            <div
              data-w-id="54dd60b7-6fa8-ef93-355b-a321568ed0f3"
              className="faq-question"
            >
              <h3 className="heading regular">Who is Nomi for?</h3>
              <img
                src="/images/plus.svg"
                loading="lazy"
                alt="Plus icon"
                className="faq-icon"
              />
            </div>
            <div className="faq-answer" style={{color: 'black'}}>
              <p>
                We built Nomi for everybody! Regardless if you're an individual, a small business, or a startup, we believe that you deserve
                to have an easier time mananging your income statements, expeditures, investment statements, and more. 
              </p>
              <div className="spacer-40" />
            </div>
          </div>

          <div className="faq-accordion">
            <div
              data-w-id="ee4ae58f-1cc5-15cd-2792-90620b0aa16a"
              className="faq-question"
            >
              <h3 className="heading regular">
                Is Nomi easy to use if you’re not technical?
              </h3>
              <img
                src="/images/plus.svg"
                loading="lazy"
                alt="Plus icon"
                className="faq-icon"
              />
            </div>
            <div className="faq-answer" style={{color: 'black'}}>
              <p>
                Absolutely! Nomi's interface is user-friendly and intuitive,
                designed for non-technical users. You can
                create custom rules or use our pre-made rules on our code-free 
                platform. Check out our{' '}
                <a 
                  href="https://app.storylane.io/share/cns5shgpkygo" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'underline', color: '#ffda8f' }}
                >
                  tutorial
                </a>
                {' '}on using Nomi best.
              </p>
              <div className="spacer-40" />
            </div>
          </div>

          <div className="faq-accordion">
            <div
              data-w-id="8ab634aa-24ca-0337-fd94-c3fe5e0a7e59"
              className="faq-question"
            >
              <h3 className="heading regular">
                How secure is my data on Nomi?
              </h3>
              <img
                src="/images/plus.svg"
                loading="lazy"
                alt="Plus icon"
                className="faq-icon"
              />
            </div>
            <div className="faq-answer" style={{color: 'black'}}>
              <p>
                Security is our top priority. Nomi uses AES-256 bit encrpytion which is the gold standard for governments and financial institutions alike. 
                We are fully compliant with all existing Plaid and financial requirements. Your data is always safe and kept private.
              </p>
              <div className="spacer-40" />
            </div>
          </div>


        </div>
      </div>
    </div>
  </section>
  <section className="section is-relative">
    <div className="cta-card-content">
      <div className="text-box">
        <div>
          <h2 className="heading extra-large" style={{color:'#ffc44d'}}>Ready to get started?</h2>
        </div>
        <div className="spacer-16" />
        <div className="paragraph extra-large" style={{color: '#14b67a'}}>
          Your first rule is just a click away.
        </div>
      </div>
      <div className="spacer-32" />
      <a onClick = {handleRegisterClick} className="button w-button">
        Get started
      </a>
    </div>
    <div className="gradient-image" />
  </section>
  <section className="section">
    <div className="container-large">
      <div className="footer-grid">
        <div className="footer-column">
          <div className="text-color-muted" style={{color: '#ffda8f'}}>Product</div>
          <a href="/" aria-current="page" className="footer-link w--current" style={{color: '#ffda8f'}}>
            Home
          </a>
          <a href="/features" className="footer-link" style={{color: '#ffda8f'}}>
            Features
          </a>
          <a href="/pricing" className="footer-link" style={{color: '#ffda8f'}}>
            Pricing
          </a>
          <a href="/sign-in" className="footer-link" style={{color: '#ffda8f'}}>
            Sign in
          </a>
        </div>
        <div
          id="w-node-_7925f3cb-1838-788e-1f37-08e45952fa20-d1959954"
          className="footer-column"
        >
          <div className="text-color-muted" style={{color: '#ffda8f'}}>Company</div>
          <a href="/about" className="footer-link" style={{color: '#ffda8f'}}>
            About
          </a>
          <a href="/contact" className="footer-link" style={{color: '#ffda8f'}}>
            Contact
          </a>
        </div>
        <div
          id="w-node-_02fd3c21-cf80-def9-a8e3-da16acbf0cfb-d1959954"
          className="footer-column"
        >
        <div className="text-color-muted" style={{color: '#ffda8f'}} ></div>
        </div>
        <div className="footer-column">

        </div>
        <div className="footer-column">
          <p style={{color: '#ffda8f'}}>
          Nomi is a financial platform, not a bank. Banking services are provided by Nomi’s bank partner(s). Nomi does not provide personal investment or financial advice. 
          </p>
        </div>
      </div>
      <div className="spacer-96" />
      <div className="divider-line" />
      <div className="spacer-40" />
      <div className="footer-bottom">
        <a href="/" aria-current="page" className="logo w-nav-brand w--current">
          <img
            src="/tab_1024.png"
            loading="lazy"
            width="Auto"
            height={24}
            alt="Logo"
          />
        </a>
        <div className="footer-link text-color-muted" style={{color: '#ffda8f'}}>© Nomi 2025</div>
      </div>
    </div>
  </section>
</>

  );
}

export default LandingPage;
