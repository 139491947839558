import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Update if needed
import axiosInstance from '../utils/axiosInstance'; // Update if needed
import '../pages/LandingPage.css'; // Webflow CSS

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');
    try {
      await login(email, password);
      setSuccess('Successfully logged in!');
      navigate('/redirect');
    } catch (err) {
      console.error(err);
      setError(err?.response?.data?.message || 'Login failed. Please check your credentials.');
    }
  };

  const handleResetPassword = async () => {
    setError('');
    setSuccess('');

    if (!email) {
      setError('To reset password, please enter your email first.');
      return;
    }
    
    try {
      axiosInstance.post('/auth/forgot-password', { email });
      setSuccess('Password reset email sent to your inbox!');
    } catch (err) {
      console.error(err);
      setError('Failed to send password reset email. Please try again.');
    }
  };

  return (
    <>
      <section className="section login">
        <div className="gradient-image" />
        <div className="container-large">
          <div className="card-item login">
            <h1 className="heading medium">Sign in</h1>
            <div className="spacer-40" />

            <div className="form-block w-form">
              <form onSubmit={handleLogin} className="login-form">
                {/* Email field */}
                <div className="text-box">
                  <label htmlFor="email" className="field-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="text-input w-input"
                    placeholder="Your email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                {/* Password field */}
                <div className="text-box">
                  <label htmlFor="password" className="field-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="text-input w-input"
                    placeholder="Your password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <div className="spacer-8" />
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleResetPassword();
                    }}
                    className="text-link"
                    style={{ color: '#fff' }}
                  >
                    Forgot password
                  </a>
                </div>

                <div className="spacer-16" />

                {/* Submit button */}
                <input
                  type="submit"
                  data-wait="Please wait..."
                  className="button full-width w-button"
                  value="Sign in"
                />
              </form>

              {/* Success/Failure Messages */}
              {success && (
                <div className="success-message w-form-done" style={{ marginTop: '20px', display: 'block', color: 'black' }}>
                  <div>{success}</div>
                </div>
              )}
              {error && (
                <div className="error-message w-form-fail" style={{ marginTop: '20px', display: 'block', color: 'black' }}>
                  <div>{error}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LoginPage;
