import React, { useState } from 'react';

const DynamicText = ({ text, baseClassName }) => {
  // Split the text into words while preserving spaces
  const words = text.split(' ');
  
  // Keep track of which word is being hovered
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className={baseClassName}>
      {words.map((word, index) => (
        <React.Fragment key={index}>
          <span
            className={`inline-block transition-all duration-200 ease-in-out
              ${hoveredIndex === index ? 'text-[#14b67a] scale-110' : ''}`}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            style={{ cursor: 'default' }}
          >
            {word}
          </span>
          {/* Add space between words, but not after the last word */}
          {index < words.length - 1 && ' '}
        </React.Fragment>
      ))}
    </div>
  );
};

export default DynamicText;