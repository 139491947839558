import React from 'react';

const CustomNavTabs = ({ activeTab, onSelect }) => {
  return (
    <div className="w-full mb-4">
      <div className="flex gap-1 border-b border-gray-200">
        <button
          style={{margin: '10px'}}
          onClick={() => onSelect('rules')}
          className={`
            px-4 py-2 text-sm font-medium rounded-t-lg
            transition-colors duration-200
            ${activeTab === 'rules' 
              ? 'bg-white text-blue-600 border-b-2 border-blue-600' 
              : 'text-gray-600 hover:text-gray-800 hover:bg-gray-50'
            }
          `}
        >
          My Rules
        </button>
        <button
          onClick={() => onSelect('applets')}
          className={`
            px-4 py-2 text-sm font-medium rounded-t-lg
            transition-colors duration-200
            ${activeTab === 'applets' 
              ? 'bg-white text-blue-600 border-b-2 border-blue-600' 
              : 'text-gray-600 hover:text-gray-800 hover:bg-gray-50'
            }
          `}
        >
          Premade Rules
        </button>
      </div>
    </div>
  );
};

export default CustomNavTabs;