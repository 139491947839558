import React from 'react';

const HeroImage = () => {
  return (
    <div className="w-full h-full relative">
      <img
        src="/images/torch-product-image-1.avif"
        alt="Product image"
        className="w-full h-full object-cover"
        style={{
          border: '2px solid #14b67a',
          borderRadius: '12px',
          // If you want to keep the transform effect, separate it into individual properties:
          transform: 'translateY(0)', // or remove if no transform needed
          opacity: 1
        }}
        sizes="(max-width: 479px) 100vw, (max-width: 767px) 83vw, (max-width: 991px) 87vw, 38vw"
        loading="lazy"
        srcSet="/images/torch-product-image-1.avif 500w, /images/torch-product-image-1.avif 1800w"
      />
    </div>
  );
};

export default HeroImage;